export const menuContent = [
	{
		name: 'Services',
		page: 'services',
		activeClass: '',
		menuClass: '',
		dropDownItems: [
			{
				name: 'Embroidery Services',
				routerPath: '/services/embroidery-services',
			},
			{
				name: 'Printing Services',
				routerPath: '/services/printing-services',
			},
			{
				name: 'Digitizing Services',
				routerPath: '/services/digitizing-services',
			},
		],
	},
	{
		name: 'About Us',
		activeClass: '',
		menuClass: '',
		page: 'about',
		routerPath: '/about',
	},
	{
		name: 'Contact Us',
		activeClass: '',
		menuClass: '',
		page: 'contact',
		routerPath: '/contact',
	},
];
