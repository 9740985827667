const companyName = process.env.NEXT_PUBLIC_COMPANY_NAME;
const companyAddressStreet = process.env.NEXT_PUBLIC_COMPANY_ADDRESS_STREET;
const companyAddressCity = process.env.NEXT_PUBLIC_COMPANY_ADDRESS_CITY;
const companyAddressCode = process.env.NEXT_PUBLIC_COMPANY_ADDRESS_CODE;
const companyAddressCountry = process.env.NEXT_PUBLIC_COMPANY_ADDRESS_COUNTRY;
const companyEmail = process.env.NEXT_PUBLIC_COMPANY_EMAIL;

const websiteName = process.env.NEXT_PUBLIC_WEBSITE_NAME;
const websiteUrl = process.env.NEXT_PUBLIC_WEBSITE_URL;
const websiteTagline = process.env.NEXT_PUBLIC_WEBSITE_TAGLINE;

export function getCompanyInfo() {
	return {
		websiteName: websiteName,
		websiteUrl: websiteUrl,
		tagline: websiteTagline,
		name: companyName,
		foundedAt: 2005,
		address: {
			street: companyAddressStreet,
			city: companyAddressCity,
			code: companyAddressCode,
			country: companyAddressCountry,
		},
		fullAddress: `${companyAddressStreet}, ${companyAddressCity}, ${companyAddressCode}, ${companyAddressCountry}`,
		email: companyEmail,
	};
}
