'use client';

import React from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { menuContent } from './menuContent';

const DropdownMenu = () => {
	let currentPage = '';
	const pathname = usePathname();

	if (pathname.split('/')[1].trim() != 'home-default') {
		currentPage = pathname.split('-')[0].split('/')[1];
	}

	return (
		<ul className="sf-menu">
			{menuContent.map((item, i) => (
				<li
					className={`menu-item-has-children ${item.menuClass}`}
					key={i}
				>
					<Link
						href={item.routerPath ?? '#'}
						className={item.activeClass}
					>
						<span
							className={
								item.page == currentPage ? 'active-page' : ''
							}
						>
							{item.name}
						</span>
					</Link>

					{item.dropDownItems ? (
						<ul className="sub-menu mega">
							{item.dropDownItems?.map((val, i) => (
								<li key={i}>
									<Link href={val.routerPath}>
										{' '}
										<span
											className={
												val.routerPath == pathname
													? 'active-page'
													: ''
											}
										>
											{val.name}
										</span>
									</Link>
								</li>
							))}
						</ul>
					) : undefined}
				</li>
			))}
		</ul>
	);
};

export default DropdownMenu;
