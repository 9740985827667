'use client';

import React from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { menuContent } from './menuContent';

import {
	ProSidebar,
	Menu,
	MenuItem,
	SubMenu,
	SidebarContent,
} from 'react-pro-sidebar';

import Social from '../../social/Social';
import { getCompanyInfo } from '@/utils/companyInfo';

const MobileMenu = () => {
	const { websiteName } = getCompanyInfo();

	let currentPage = '';
	const pathname = usePathname();

	currentPage = pathname.split('-')[0].split('/')[1];

	if (
		currentPage !== 'works' &&
		currentPage !== 'blog' &&
		currentPage !== 'home' &&
		currentPage !== 'services'
	) {
		currentPage = 'others';
	}
	// titleStyle={item.page == currentPage ? {color: '#fa4529'}:''}

	return (
		<>
			<div className="ptf-offcanvas-menu__navigation">
				<ProSidebar>
					<SidebarContent>
						<Menu className="sidebar-menu_wrapper">
							{menuContent.map((item, i) =>
								item.dropDownItems ? (
									<SubMenu
										className={
											item.page == currentPage
												? 'active-page'
												: ''
										}
										title={item.name}
										key={i}
									>
										{item.dropDownItems.map((val, i) => (
											<MenuItem key={i}>
												<Link
													className={
														val.routerPath ==
														pathname
															? 'active-page'
															: ''
													}
													href={val.routerPath}
												>
													{val.name}
												</Link>
											</MenuItem>
										))}
									</SubMenu>
								) : (
									<MenuItem key={i}>
										<Link
											className={
												item.routerPath == pathname
													? 'active-page'
													: ''
											}
											href={item.routerPath}
										>
											{item.name}
										</Link>
									</MenuItem>
								)
							)}
						</Menu>
					</SidebarContent>
				</ProSidebar>
			</div>
			{/* End .ptf-offcanvas-menu__navigation */}

			<div className="ptf-offcanvas-menu__footer">
				<p className="ptf-offcanvas-menu__copyright">
					@{new Date().getFullYear()} <span>{websiteName}</span>. All
					Rights Reserved.
				</p>
				<Social />
			</div>
			{/* End .ptf-offcanvas-menu__footer */}
		</>
	);
};

export default MobileMenu;
