'use client';

import React from 'react';
import SocialTwo from '../../social/SocialTwo';
import Image from 'next/image';
import { getCompanyInfo } from '@/utils/companyInfo';

const logo = '/images/logo/logo-dark.svg';

const CopyRight = () => {
	const { name, websiteName } = getCompanyInfo();
	return (
		<div className="row align-items-center justify-content-center">
			<div className="col-12 col-md">
				<a href="#">
					<Image
						width={1200}
						height={1200}
						style={{ width: '220px', height: '46px' }}
						src={logo}
						alt="logo"
						loading="lazy"
					/>
				</a>
			</div>
			<div className="col-12 col-md text-md-center text-lg-center">
				<p className="ptf-footer-copyright has-black-color">
					©{new Date().getFullYear()}{' '}
					<span>
						{websiteName} by {name}.
					</span>{' '}
					All Rights Reserved.
				</p>
			</div>
			<div className="col-12 col-lg text-md-center text-lg-end">
				<div className="ptf-footer-socials has-black-color ">
					<SocialTwo />
				</div>
			</div>
		</div>
	);
};

export default CopyRight;
