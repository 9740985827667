'use client';

import React from 'react';
import Link from 'next/link';

import { getCompanyInfo } from '@/utils/companyInfo';

const linkList = [
	{
		itemName: 'Home',
		link: '/',
	},
	{
		itemName: 'About Us',
		link: '/about',
	},
	{
		itemName: 'Contact Us',
		link: '/contact',
	},
	{
		itemName: 'Privacy Policy',
		link: '/privacy-policy',
	},
	{
		itemName: 'Cookie Policy',
		link: '/cookie-policy',
	},
];

const services = [
	{
		itemName: 'Embroidery Services',
		link: '/services/embroidery-services',
	},
	{
		itemName: 'Printing Services',
		link: '/services/printing-services',
	},
	{
		itemName: 'Digitizing Services',
		link: '/services/digitizing-services',
	},
];

const Footer = () => {
	const { fullAddress, email } = getCompanyInfo();
	return (
		<div className="row">
			<div className="col-12 col-xl-6">
				<div>
					<div
						className="ptf-footer-heading has-black-color"
						style={{ maxWidth: '37.5rem' }}
					>
						Do you need a reliable company that can facilitate your
						ideas into reality?
					</div>

					<div className="ptf-footer-heading has-black-color">
						Start by{' '}
						<Link
							className="has-accent-1 text-decoration-underline"
							href="/contact"
						>
							Saying Hi!
						</Link>
					</div>

					<p className="fz-24">{fullAddress}</p>
					<a
						className="fz-24 has-black-color"
						href={`mailto:${email}`}
					>
						{email}
					</a>
				</div>
			</div>
			<div className="col-12 col-md-6 col-xl-3">
				<div>
					<div className="ptf-widget ptf-widget-links has-black-color">
						<h4 className="ptf-widget-title">Links</h4>
						<ul>
							{linkList.map((val, i) => (
								<li key={i}>
									<Link href={val.link}>{val.itemName}</Link>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
			<div className="col-12 col-md-6 col-xl-3">
				<div>
					<div className="ptf-widget ptf-widget-text">
						<h4 className="ptf-widget-title">Services</h4>
						<div className="ptf-widget ptf-widget-links has-black-color">
							<ul>
								{services.map((val, i) => (
									<li key={i}>
										<Link href={val.link}>
											{val.itemName}
										</Link>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
